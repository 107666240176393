// extracted by mini-css-extract-plugin
export const root = "PlasmicJobList-module--root--21nBM";
export const box__eAr6 = "PlasmicJobList-module--box__eAr6--2E1mW";
export const box__zNrUu = "PlasmicJobList-module--box__zNrUu--2nxBM";
export const box__qXiE0 = "PlasmicJobList-module--box__qXiE0--1HaEK";
export const box__kgMqN = "PlasmicJobList-module--box__kgMqN--1P8_w";
export const box__u5TIf = "PlasmicJobList-module--box__u5TIf--1bfOD";
export const button = "PlasmicJobList-module--button--30wKs";
export const job__fhYqK = "PlasmicJobList-module--job__fhYqK--3cneb";
export const job__mBv4I = "PlasmicJobList-module--job__mBv4I--3CxOM";
export const job__toJtj = "PlasmicJobList-module--job__toJtj--1B0gr";