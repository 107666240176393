// extracted by mini-css-extract-plugin
export const root = "PlasmicJob-module--root--1wh9s";
export const box__xf25N = "PlasmicJob-module--box__xf25N--3pMPN";
export const box__cwsOt = "PlasmicJob-module--box__cwsOt--3o3n3";
export const box__b3Ae8 = "PlasmicJob-module--box__b3Ae8--2Y9_t";
export const box__k1NYt = "PlasmicJob-module--box__k1NYt--2VGQr";
export const box__bLbTd = "PlasmicJob-module--box__bLbTd--P2Vgj";
export const box___5Znsr = "PlasmicJob-module--box___5Znsr--W_boB";
export const box__scOkh = "PlasmicJob-module--box__scOkh--3gSOr";
export const box__zFyWe = "PlasmicJob-module--box__zFyWe--s5Jz0";
export const box___7Fwfu = "PlasmicJob-module--box___7Fwfu--2qhAi";
export const box__pdSfh = "PlasmicJob-module--box__pdSfh--3xXKP";
export const box__y6O2 = "PlasmicJob-module--box__y6O2--3wLcl";
export const box___5Kztt = "PlasmicJob-module--box___5Kztt--3jGvY";